import React, { HTMLProps } from "react";
import './InputActive.scss';
import { InputBlock } from "Components/Form";
import { InputType } from "Models";

type InputActiveProps = {
  id: string;
  label: string;
  description?: string;
  error?: string;
} & HTMLProps<HTMLDivElement>;

export const InputActive: React.FC<InputActiveProps> = ({
  id,
  label,
  description,
  error,
  ...props
}) => {
  return (
    <div className="input-container" {...props}>
      <h6 className="label">{label} {props.required && <span style={{ color: "#BD0707" }}>*</span>}</h6>
      <InputBlock 
        id={id}
        name={id}
        className="input"
        type={InputType.Text}
        value={props.value}
        onChange={props.onChange}
        useDiv
        noPaddingMargin
        errors={!!error ? [error] : undefined}
      />
      {!error && description && (<p className="description">{description}</p>)}
    </div>
  );
};