import './NewReferralButton.scss'
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React, { useEffect, useState } from 'react';
import { buildRoute, EMPTY_GUID, useDataFetcher } from 'Utilities';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Link } from 'react-router-dom';
import { AddEditSubRoute, ROUTES } from 'Navigation';
import { AsyncStatus } from 'Models';
import { useHistory } from "react-router-dom";

type LineOfBusinessEnumModel = {
  displayName: string;
  value: number;
}

type ServiceModel = {
}

type SubServiceModel = {
}

type ReportQuestionModel = {
}

type LineOfBusinessModel = {
  lineOfBusiness: LineOfBusinessEnumModel,
  service: ServiceModel,
  subService: SubServiceModel,
  reportQuestion: ReportQuestionModel[]
}

const NewReferralButton: React.FC = () => {
  const history = useHistory();
  const [hasOneLob, setHasOneLob] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [linesOfBusiness, fetchStatus] = useDataFetcher<LineOfBusinessModel[]>(
    `${ENDPOINTS.users.base}/lineOfBusiness`,
    [],
    ({ data }: any) => data,
    undefined,
    true,
    "3.0"
  );

  useEffect(() => {
    if (linesOfBusiness?.length === 1) {
      setHasOneLob(true);
    }
  }, [linesOfBusiness]);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  function navigateToNewReferral(lob: number) {
    const route = buildRoute(
      ROUTES.ADD_EDIT,
      AddEditSubRoute.ReferralsRequest,
      EMPTY_GUID,
      ''
    ) + `?lob=${lob}`
    history.push(route);
  }

  const NewReferralDropdown = () => {
    return (
      <div className='buttonWrapper'>
        <button onClick={toggleDropdown} className='newReferralButton'>
          <span className='newReferralText'>New Referral</span>
          <span className='arrowIcon'>
            {!isOpen ? (<KeyboardArrowDown />) : (<KeyboardArrowUp />)}
          </span>
        </button>
        {isOpen && (
          <div>
            <ul className='newReferralList'>
              {linesOfBusiness?.map((lob) => (
                <li
                  key={lob.lineOfBusiness.value}
                  onClick={() => navigateToNewReferral(lob.lineOfBusiness.value)}
                >
                  <span className="linkText">
                    {lob.lineOfBusiness.displayName}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }

  const SingleLobButton = () => {
    return (
      <div className='buttonWrapper'>
        <button onClick={() => navigateToNewReferral(linesOfBusiness[0].lineOfBusiness.value)} className='newReferralButton'>
          <span className='newReferralText'>New Referral</span>
        </button>
      </div>
    );
  }

  return (
    <>
      {fetchStatus === AsyncStatus.Success &&
        (
          hasOneLob ? (
            <SingleLobButton />
          ) : (
            <NewReferralDropdown />
          )
        )}
    </>
  );
};

export default NewReferralButton;
