import { Clear, DescriptionOutlined, WarningAmberOutlined } from '@mui/icons-material';
import './FileUploader.scss';
import { ErrorDisplay, Render } from 'Components/Display';
import { getFieldValue } from 'Context';
import {
  FilePondErrorDescription,
  FilePondFile,
  FileStatus,
  ProcessServerConfigFunction,
  RevertServerConfigFunction,
} from 'filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import { METHODS } from 'Models/Templates/Routes/Routes';
import React, { useEffect, useRef, useState } from 'react';
import { FilePond, FilePondProps, registerPlugin } from 'react-filepond';
import {
  buildEndpoint,
  buildQueryUrl,
  DOCX_MIME_FILE_TYPE,
  DOC_MIME_FILE_TYPE,
  FILE_TYPE_MAP,
  getPropertyByString,
  PDF_MIME_FILE_TYPE,
  toggledClass,
  useApiWorker,
  XLSX_MIME_FILE_TYPE,
  XLS_MIME_FILE_TYPE,
} from 'Utilities';
import { v4 as uuidv4 } from 'uuid';
import { Spinner } from 'reactstrap';

export type FileData = {
  fileId: string;
  fileName: string;
  size: number;
  id?: string;
}

export type FilePondDataOptions = {
  name: string;
  size: number;
  type: string;
}

export type FilePondData = {
  source: string;
  options: FilePondDataOptions;
}

type FileUploaderProps = FilePondProps & {
  fileEndpoint: string;
  name?: string;
  isEdit?: boolean;
  label: string;
  formValues: any;
  formErrors?: any;
  allowMultiple?: boolean;
  onFilesChange?: (files: any[] | undefined) => void;
};

export const FileUploader: React.FC<FileUploaderProps> = ({
  fileEndpoint,
  name = 'files',
  isEdit,
  label,
  formValues,
  formErrors,
  allowMultiple,
  onFilesChange,
  ...filePondProps
}) => {
  const API = useApiWorker("3.0");
  var filePondRef = useRef<FilePond | null>(null);
  const [hasFiles, setHasFiles] = useState<boolean>(false);
  const [fileErrors, setFileErrors] = useState<string[]>([]);
  const [filePondFiles, setFilePondFiles] = useState<FilePondFile[]>([]);
  const [files, setFiles] = useState(
    (formValues?.[name] || []).map((file: any) => ({
      source: file.fileId,
      options: {
        file: {
          name: file.fileName,
          size: file.size
        },
        type: 'local' as 'local',
        metadata: {
          backendId: file.id
        }
      }
    })
  ));
  const [showFormatErrorPanel, setShowFormatErrorPanel] = useState(false);

  const uploadUrl = buildEndpoint(fileEndpoint, METHODS.Upload);
  const deleteUrl = buildEndpoint(fileEndpoint, METHODS.DeleteTempBlob);

  const allowedFileTypes = [
    PDF_MIME_FILE_TYPE,
    DOC_MIME_FILE_TYPE,
    DOCX_MIME_FILE_TYPE,
    XLS_MIME_FILE_TYPE,
    XLSX_MIME_FILE_TYPE,
  ];

  registerPlugin(FilePondPluginFileValidateType);
  useEffect(() => {
    var hasValues =
      (allowMultiple && getPropertyByString(formValues, name)?.length > 0) ||
      (formValues?.[name]?.fileId?.length > 0) ||
      (!allowMultiple && formValues?.fileId?.length > 0);
    if (hasFiles !== hasValues) {
      setHasFiles(hasValues);
    }
  }, [formValues]);

  useEffect(() => {
    // const fileErrorValues = formErrors[formatFieldName('fileId')];

    // setFileErrors(fileErrorValues);
  }, [formErrors]);

  useEffect(() => {
    if (filePondRef && !hasFiles) {
      filePondRef.current?.removeFiles();
    }
  }, [hasFiles]);

  const clearFileFields = () => {
    onFilesChange?.([]);
  };

  const deleteUrlWithId = (id: string) => {
    return buildQueryUrl(deleteUrl, { id: id });
  };

  /* Handles chunking + posting temp file in chunks */
  const handleProcess: ProcessServerConfigFunction = async (
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort
  ) => {
    var aborted = false;
    const handleAbort = () => {
      aborted = true;
      abort();
    };
    const CHUNK_SIZE = 1024 * 1024 * 10;
    var chunk = 0;
    const fileId = uuidv4();
    var numChunks = Math.ceil(file.size / CHUNK_SIZE);

    while (chunk * CHUNK_SIZE < file.size) {
      var blob = file.slice(CHUNK_SIZE * chunk, CHUNK_SIZE * (chunk + 1));
      const formData = new FormData();
      formData.append('contentType', file.type);
      formData.append('fileName', file.name);
      formData.append('chunk', `${chunk}`);
      formData.append('chunks', `${numChunks}`);
      formData.append('id', fileId);
      formData.append('file', blob);
      const response = await API.post(uploadUrl, formData);
      chunk++;
      progress(true, chunk, numChunks);
    }

    load(fileId);

    return {
      abort: handleAbort,
    };
  };

  /* Handles deleting temp file */
  const handleRevert: RevertServerConfigFunction = async (
    uniqueFileId,
    load,
    error
  ) => {
    try {
      await API.post(deleteUrlWithId(uniqueFileId));
    } catch (err) {
      error(err);
    }

    if (allowMultiple) {
      const filteredFiles = (filePondRef.current?.getFiles() || []).filter(
        (file: any) => file.serverId !== uniqueFileId
      );

      onFilesChange?.(filteredFiles);
    } else {
      clearFileFields();
    }

    load();
  };

  const handleProcessedFile = (error: any, file: any) => {
    const files = filePondRef.current?.getFiles();
    onFilesChange?.(files)
  };

  const onUpdateFiles = (files: FilePondFile[]) => {
    setFilePondFiles(files);
    setFiles(files);

    onFilesChange?.(files);
  }

  const server = {
    process: handleProcess,
    revert: handleRevert,
  };

  return (
    <div className='file-uploader'>
      <Render condition={!isEdit}>
        {showFormatErrorPanel && (
          <FormatErrorPanel onClose={() => setShowFormatErrorPanel(false)} />
        )}
        <div style={{ marginBottom: 5 }}>{label}</div>
        <div
          className={toggledClass('with-errors', fileErrors?.length > 0)}
        >
          <FilePond
            name={name}
            server={server}
            files={files}
            onprocessfile={handleProcessedFile}
            onupdatefiles={onUpdateFiles}
            onaddfile={(e: FilePondErrorDescription | null, f: FilePondFile) => {
              if (!!e) {
                setShowFormatErrorPanel(true);
                filePondRef.current?.removeFile(f, { revert: true })
              }
            }}
            ref={(ref) => (filePondRef.current = ref)}
            allowMultiple={allowMultiple}
            acceptedFileTypes={allowedFileTypes}
            fileValidateTypeLabelExpectedTypesMap={FILE_TYPE_MAP}
            stylePanelLayout='compact'     
            labelIdle={`
              <div style="
                display: flex;
              ">
                <div style="
                  display: flex; 
                  align-items: center; 
                  justify-content: space-between; 
                  border: 1px solid #007bff;
                  padding: 10px;
                  background-color: #f9f9f9;
                  cursor: pointer;
                ">
                  <!-- Button on the left -->
                  <div style="display: flex; align-items: center; gap: 8px; color: #007bff;">
                    <svg xmlns="http://www.w3.org/2000/svg" style="width: 24px; height: 24px; fill: #007bff;" viewBox="0 0 24 24">
                      <path d="M5 20h14v-2H5v2zm7-14 5 5h-3v4h-4v-4H7l5-5z"/>
                    </svg>
                      
                    <strong>Select files</strong>
                  </div>
                </div>
      
                <!-- Hint text on the right -->
                <div style="
                  margin-left: auto;
                ">
                  <div>
                    Drop files here to upload
                  </div>
                  <div style="color: #666; font-size: 12px;">
                    .docx, .pdf, or .xls (max. 1 GB each)
                  </div>
                </div>
              </div>
            `}
            {...filePondProps}
          />
          <div className='file-list'>
            {filePondFiles.map((f: FilePondFile) => (
              <FileItem
                key={f.id}
                file={f}
                onDelete={() => filePondRef.current?.removeFile(f, { revert: true })} 
              />
            ))}
          </div>
        </div>
        <ErrorDisplay errors={fileErrors} />
      </Render>
    </div>
  );
};

const FileItem = ({
  file,
  onDelete
}: {
  file: FilePondFile;
  onDelete: () => void;
}) => {
  return (
    <div key={file.id} className='file-item'>
      <DescriptionOutlined className='file-icon'/>
      <div>
        <p className='filename'>{file.filename}</p>
        {file.status === FileStatus.PROCESSING_COMPLETE ? (
          <p className='success-text'>File successfully uploaded.</p>
        ) : (
          <p className='loading-text'>Loading</p>
        )}
      </div>
      {file.status === FileStatus.PROCESSING_COMPLETE ? (
        <Clear 
          className='delete-icon'
          onClick={onDelete}
        />
      ) : (
        <Spinner className='loading-icon'/>
      )}
    </div>
  );
}
//filePondRef.current?.removeFile(file, { revert: true });

const FormatErrorPanel = ({ 
  onClose 
}: { 
  onClose: () => void 
}) => {
  return (
    <div className="format-error-panel">
      <WarningAmberOutlined className="warning-icon"/>
      <div>
        <p>File format not supported.</p>
        <p>You can upload any of the following formats: .pdf, .docx, and .xls</p>
      </div>
      <Clear 
        className='close-icon'
        onClick={onClose}
      />
    </div>
  );
}