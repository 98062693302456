import React from "react";
import ReadOnlyField from "v3/Components/Form/ReadOnlyField";
import { FileUploader } from "v3/Components/Form/FileUploader";
import { CONTROLLERS } from "Models/Templates/Routes/Routes";
import { useIdParam } from "Utilities";
import { TitleActive } from "v3/Components/Form/TitleActive";
import { TitleReadOnly } from "v3/Components/Form/TitleReadOnly";
import { InputActive } from "v3/Components/Form/InputActive";
import { FilePondFile } from "filepond";
import './AddEditReferralsRequest.AdditionalInformation.scss';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { FieldError } from "v3/Components/Form/FormSection";
import { ReferralRequestSchema, useReferralRequestForm } from "./useReferralRequestForm";

type AdditionalInformationProps = {
  form: ReturnType<typeof useReferralRequestForm>;
  fieldErrors?: FieldError[];
}

export const AdditionalInformationActive: React.FC<AdditionalInformationProps> = ({
  form,
  fieldErrors
}) => {
  const [id, isAdd, isEdit] = useIdParam();

  return (
    <div className="">
      <TitleActive>Special Instructions</TitleActive>
      <InputActive 
        id="specialInstructions"
        name="specialInstructions" 
        label="Comments" 
        value={form.getFieldProps("specialInstructions").value ?? ""}
        onChange={(e: any) => {
          form.setFieldValue("specialInstructions", e.target.value)
        }}
        required
        style={{
          width: '100%'
        }}
        error={fieldErrors?.find(f => f.fieldId === "specialInstructions")?.error}
      />
      <TitleActive>Medical Records</TitleActive>
      <FileUploader
        label="Upload the necessary documents attached to this case." 
        fileEndpoint={`${CONTROLLERS.ReferralsRequestsDraft}/Document`} 
        name="referralRequestDraftDocuments" 
        isEdit={isEdit}
        allowMultiple
        formValues={form.values}
        onFilesChange={(files: any) => onFilesChange(files, form)}
        >
      </FileUploader>
    </div>
  );
}

const onFilesChange = (files: FilePondFile[], form: ReturnType<typeof useReferralRequestForm>) => {
  const newFiles = files.map((file) => {
    return {
      fileId: file.serverId,
      fileName: file.filename,
      size: file.fileSize,
      id: file.getMetadata('backendId')
    }
  })
  form.setFieldValue("referralRequestDraftDocuments", newFiles)
}

export const AdditionalInformationReadOnly: React.FC<AdditionalInformationProps> = ({
  form
}) => {

  return (
    <div className="read-only-content">
      <TitleReadOnly>Special Instructions</TitleReadOnly>
      <ReadOnlyField
        id="specialInstructions"
        schema={ReferralRequestSchema}
        value={form.getFieldProps("specialInstructions").value} />
      <TitleReadOnly>Document(s)</TitleReadOnly>
      {!form.values.referralRequestDraftDocuments?.length ? (
        <p>No documents</p>
      ) : (
        form.values.referralRequestDraftDocuments.map(c => (
          <div key={c.fileId} className="document">
            <DescriptionOutlinedIcon />
            <span>{c.fileName}</span>
          </div>
        ))
      )}
    </div>
  )
}