import React from "react";
import { Button } from '@progress/kendo-react-buttons';
import './AddEditClient.Header.scss';
import { useHistory } from "react-router-dom";

export type AddEditClientHeaderProps = {
  onSubmit: () => void
};

const AddEditClientHeader: React.FC<AddEditClientHeaderProps> = ({
  onSubmit
}) => {
  let history = useHistory();

  return (
    <div className="client-header">
      <h1>New Client</h1>
      <Button 
        primary 
        look="outline" 
        className="ml-auto"
        onClick={() => {
          if (window.confirm("Are you sure you want to leave this page? If so, you will lose all the information on this new client.")) {
            history.goBack();
          }
        }}
      >
        Cancel
      </Button>
      <Button 
        primary 
        style={{ marginLeft: 20 }}
        onClick={onSubmit}
      >
        Add Client
      </Button>
    </div>
  );
}

export default AddEditClientHeader;