import React from 'react';
import { DatePicker, TimePicker } from '@progress/kendo-react-dateinputs';
import './DateTimePicker.scss';

interface DateTimePickerInputProps {
  label: string;
  value: Date;
  onChange: (e: any) => void;
  required?: boolean;
}

export const DateTimePickerInput = (props: DateTimePickerInputProps) => {
  const { label, required, value, onChange } = props;

  const [dateValue, setDateValue] = React.useState(new Date(value));
  const [timeValue, setTimeValue] = React.useState(new Date(value));

  const onDateChange = (e: any) => {
    const date = e.target.value;
    const updatedDate = new Date(
      date.setHours(timeValue.getHours(), timeValue.getMinutes())
    );
    setDateValue(updatedDate);

    const formattedDateTime = updatedDate.toISOString();
    onChange(formattedDateTime);
  };

  const onTimeChange = (e: any) => {
    const newTime = new Date(e.target.value);
    setTimeValue(newTime);

    const updatedDate = new Date(
      dateValue.setHours(newTime.getHours(), newTime.getMinutes())
    );

    const formattedDateTime = updatedDate.toISOString();
    onChange(formattedDateTime);
  };

  return (
    <div className="date-picker-wrapper">
      <span className="date-picker-label">
        {label} {required ? <span className="required">*</span> : '(Optional)'}
      </span>
      <div className="date-time-picker-row">
        <DatePicker
          value={dateValue}
          onChange={onDateChange}
          format="MM/dd/yyyy"
        />
        <TimePicker value={timeValue} onChange={onTimeChange} />
      </div>
    </div>
  );
};
