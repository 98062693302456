import { SingleSectionPageCard } from 'Components/Display';

import React, { Fragment, useState } from 'react';
import { DatePickerInput } from '../Form/DateTimePickers/DatePicker';
import { DateTimePickerInput } from '../Form/DateTimePickers/DateTimePicker';
import Dropdown, { DropdownOption } from '../Form/Dropdown';

export const ComponentLibrary = () => {
  const [dropdownSelectedItem, setDropdownSelectedItem] =
    useState<DropdownOption>({ value: '', displayName: '' });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isRequired, setIsRequired] = useState(false); // State for checkbox

  const handleDateChange = (e: any) => {
    console.log(e.target.value);
    setSelectedDate(e.target.value);
  };

  const dropdownOptions = [
    { value: 'option1', displayName: 'Option 1' },
    { value: 'option2', displayName: 'Option 2' },
    { value: 'option3', displayName: 'Option 3' },
  ];
  const handleCheckboxChange = (event: any) => {
    setIsRequired(event.target.checked);
  };
  return (
    <Fragment>
      <h1>Component Library</h1>
      <div style={{ marginBottom: '10px', marginTop: '10px' }}>
        <input
          type="checkbox"
          id="required-checkbox"
          checked={isRequired}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="required-checkbox" style={{ marginLeft: '5px' }}>
          Mark fields as required
        </label>
      </div>
      <SingleSectionPageCard cardTitle="" centerContent inputSection>
        <div className="active-content">
          <div className="active-row">
            <div
              className="active-fields"
              style={{ width: '100%', alignItems: 'flex-start' }}
            >
              <Dropdown
                label="Dropdown"
                hint="Select an option"
                required={isRequired}
                value={
                  dropdownOptions.find(
                    (option) => option.value === dropdownSelectedItem.value
                  ) || { value: '', displayName: '' }
                }
                onChange={(value) => setDropdownSelectedItem(value)}
                options={dropdownOptions}
              />

              <DatePickerInput
                label={'Date picker component'}
                value={selectedDate}
                required={isRequired}
                onChange={handleDateChange}
              />
            </div>
          </div>
          <div className="active-row">
            <div
              className="active-fields"
              style={{ width: '100%', alignItems: 'flex-start' }}
            >
              <DateTimePickerInput
                label={'DateTime picker component'}
                value={selectedDate}
                required={isRequired}
                onChange={(value: any) => {
                  const date = new Date(value);
                  const options = {
                    timeZone: 'America/Sao_Paulo',
                    hour12: false,
                  };
                  const formattedDate = new Intl.DateTimeFormat('en-US', {
                    ...options,
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                  }).format(date);
                  console.log(formattedDate);
                }}
              />
            </div>
          </div>
        </div>
      </SingleSectionPageCard>
    </Fragment>
  );
};
