
export class Client {
  id?: string;
  name?: string;
  contactEmail?: string;
  isActive?: boolean;
  clientPrimaryContacts?: PrimaryContact[];
  clientBillingContacts?: BillingContact[];
  clientContracts?: Contract[];
}

export type Contract = {
  fileId: string;
  fileName: string;
  size: string;
}

export type PrimaryContact = {
  primaryContactName: string;
  primaryContactPhone: string;
  primaryContactEmail: string;
}

export type BillingContact = {
  billingContactName: string;
  billingContactPhone: string;
  billingContactEmail: string;
}