import React, { useState, useEffect, useRef } from 'react';
import './Dropdown.scss';
import arrowUp from 'Assets/arrow_up.svg';
import arrowDown from 'Assets/arrow_down.svg';
import { NamedCoreEnum } from 'Models';

export type DropdownValue = string | number | undefined;
export type DropdownDisplayName = string | number | undefined;
export type DropdownOption = {
  value: DropdownValue;
  displayName: DropdownDisplayName;
};

interface Option {
  value: DropdownValue;
  label: DropdownDisplayName;
}

interface DropdownProps {
  label: DropdownDisplayName;
  hint: string;
  value: DropdownOption;
  onChange: (option: DropdownOption) => void;
  options: DropdownOption[];
  required?: boolean;
  enums?: NamedCoreEnum;
  valueAsDisplayName?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  label,
  hint,
  value,
  onChange,
  options,
  required,
  enums,
  valueAsDisplayName: valueAsLabel,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);

  if (enums) {
    options = Object.keys(enums).map((key) => ({
      value: enums[key].value,
      displayName: valueAsLabel ? enums[key].value : enums[key].displayName,
    }));
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (value: DropdownOption) => {
    onChange(value);
    setIsOpen(false);
  };

  return (
    <div className="dropdown-container">
      <label>
        {label} {required ? <span className="required">*</span> : '(Optional)'}
      </label>

      <div
        ref={inputRef}
        onClick={handleToggle}
        className="dropdown-input-container"
        tabIndex={0}
      >
        <span>
          {options.find((option) => option.value === value?.value)
            ?.displayName ||
            '' ||
            '--'}
        </span>
        <img
          src={isOpen ? arrowUp : arrowDown}
          alt={isOpen ? 'Arrow Up' : 'Arrow Down'}
        />
      </div>

      <div ref={dropdownRef} className={`dropdown ${isOpen ? 'open' : ''}`}>
        <div
          className={`dropdown-option-item ${
            value?.value === '' ? 'selected' : ''
          }`}
          onClick={() => handleSelect({ value: '', displayName: '' })}
        >
          --
        </div>
        {options.map((option) => (
          <div
            key={option.value}
            className={`dropdown-option-item ${
              value?.value === option.value ? 'selected' : ''
            }`}
            onClick={() => handleSelect(option)}
          >
            {option.displayName}
          </div>
        ))}
      </div>

      <div className="hint">{hint}</div>
    </div>
  );
};

export default Dropdown;
