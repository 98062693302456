import React from "react";
import { Button } from '@progress/kendo-react-buttons';
import './AddEditReferralsRequest.Header.scss';

export type AddEditReferralsRequestHeaderProps = {
  onSave: () => void;
  onDiscard: () => void;
};

const AddEditReferralsRequestHeader: React.FC<AddEditReferralsRequestHeaderProps> = ({
  onSave,
  onDiscard
}) => {
  return (
    <div className="referral-header">
      <h1>Referral Request</h1>
      <Button 
        primary 
        look="flat" 
        className="ml-auto"
        onClick={onDiscard}
      >
        Discard Draft
      </Button>
      <Button 
        primary 
        look="outline" 
        style={{ marginLeft: 20 }}
        onClick={onSave}
      >
        Save Draft & Finish Later
      </Button>
    </div>
  );
}

export default AddEditReferralsRequestHeader;