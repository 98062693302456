import './AddEditClient.ContactInformation.scss'
import React, { useEffect } from "react";
import { useClientForm, ClientSchema } from "./useClientForm";
import ReadOnlyField from "v3/Components/Form/ReadOnlyField";
import { Clear } from '@mui/icons-material';
import { TitleActive } from 'v3/Components/Form/TitleActive';
import { TitleReadOnly } from 'v3/Components/Form/TitleReadOnly';
import { InputActive } from 'v3/Components/Form/InputActive';
import AddIcon from '@mui/icons-material/Add';
import { FieldError } from 'v3/Components/Form/FormSection';

type ContactInformationProps = {
  form: ReturnType<typeof useClientForm>;
  fieldErrors?: FieldError[];
}

export const ContactInformationActive: React.FC<ContactInformationProps> = ({
  form,
  fieldErrors
}) => {
  const addPrimaryContactRow = () => {
    form.setFieldValue("clientPrimaryContacts", [
      ...(form.values.clientPrimaryContacts ?? []),
      {
        primaryContactName: "",
        primaryContactPhone: "",
        primaryContactEmail: ""
      }
    ]);
  };

  const addBillingContactRow = () => {
    form.setFieldValue("clientBillingContacts", [
      ...(form.values.clientBillingContacts ?? []),
      {
        billingContactName: "",
        billingContactPhone: "",
        billingContactEmail: ""
      }
    ]);
  };

  useEffect(() => {
    const primaryContacts = form.values.clientPrimaryContacts;
    if (!primaryContacts || primaryContacts.length === 0) {
      addPrimaryContactRow();
    }

    const billingContacts = form.values.clientBillingContacts;
    if (!billingContacts || billingContacts.length === 0) {
      addBillingContactRow();
    }
  }, [])

  return (
    <div className="">

      <TitleActive>Primary Contact</TitleActive>
      {form.values.clientPrimaryContacts?.map((contact, index) => (
        <PrimaryContact 
          key={"primary-contact-" + index}
          index={index}
          form={form} 
          fieldErrors={fieldErrors}
        />
      ))}
      <div
        className='add-contact-button'
        onClick={() => addPrimaryContactRow()}
        style={{
          marginBottom: 40
        }}
      >
        <AddIcon />
        <span>Add Primary Contact</span>
      </div>
      <TitleActive>Billing Contact</TitleActive>
      {form.values.clientBillingContacts?.map((contact, index) => (
        <BillingContact
          key={"billing-contact-" + index}
          index={index}
          form={form} 
          fieldErrors={fieldErrors}
        />
      ))}
      <div
        className='add-contact-button'
        onClick={() => addBillingContactRow()}
      >
        <AddIcon />
        <span>Add Billing Contact</span>
      </div>
    </div>
  );
}

const PrimaryContact = ({
  index,
  form,
  fieldErrors
}: { 
  index: number;
  form: ReturnType<typeof useClientForm>;
  fieldErrors?: FieldError[];
}) => {
  return (
    <div key={index} className="contact-row">
      <div className='contact-fields'>
        <InputActive
          id={`clientPrimaryContacts[${index}].primaryContactName`}
          label="Primary Contact Name"
          value={form.getFieldProps(`clientPrimaryContacts[${index}].primaryContactName`).value ?? ""}
          onChange={(e: any) => {
            form.setFieldValue(`clientPrimaryContacts[${index}].primaryContactName`, e.target.value)
          }}
          error={fieldErrors
            ?.find(f => f.fieldId === `clientPrimaryContacts[${index}].PrimaryContactName`)
            ?.error
          }
        />
        <InputActive
          id={`clientPrimaryContacts[${index}].primaryContactPhone`}
          label="Primary Contact Phone Number"
          value={form.getFieldProps(`clientPrimaryContacts[${index}].primaryContactPhone`).value ?? ""}
          onChange={(e: any) => {
            form.setFieldValue(`clientPrimaryContacts[${index}].primaryContactPhone`, e.target.value)
          }}
          error={fieldErrors
            ?.find(f => f.fieldId === `clientPrimaryContacts[${index}].PrimaryContactPhone`)
            ?.error
          }
        />
        <InputActive
          id={`clientPrimaryContacts[${index}].primaryContactEmail`}
          label="Primary Contact Email"
          value={form.getFieldProps(`clientPrimaryContacts[${index}].primaryContactEmail`).value ?? ""}
          onChange={(e: any) => {
            form.setFieldValue(`clientPrimaryContacts[${index}].primaryContactEmail`, e.target.value)
          }}
          error={fieldErrors
            ?.find(f => f.fieldId === `clientPrimaryContacts[${index}].PrimaryContactEmail`)
            ?.error
          }
        />
      </div>
      <Clear 
        className='x-icon'
        onClick={() => {
          form.setFieldValue("clientPrimaryContacts", form.values.clientPrimaryContacts?.filter((_, i) => i !== index))
        }}
      />
    </div>
  );
}

const BillingContact = ({
  index,
  form,
  fieldErrors
}: { 
  index: number;
  form: ReturnType<typeof useClientForm>;
  fieldErrors?: FieldError[];
}) => {
  return (    
    <div key={index} className="contact-row">
      <div className='contact-fields'>
        <InputActive
          id={`clientBillingContacts[${index}].billingContactName`}
          label="Billing Contact Name"
          value={form.getFieldProps(`clientBillingContacts[${index}].billingContactName`).value ?? ""}
          onChange={(e: any) => {
            form.setFieldValue(`clientBillingContacts[${index}].billingContactName`, e.target.value)
          }}
          error={fieldErrors
            ?.find(f => f.fieldId === `clientBillingContacts[${index}].BillingContactName`)
            ?.error
          }
        />
        <InputActive
          id={`clientBillingContacts[${index}].billingContactPhone`}
          label="Billing Contact Phone Number"
          value={form.getFieldProps(`clientBillingContacts[${index}].billingContactPhone`).value ?? ""}
          onChange={(e: any) => {
            form.setFieldValue(`clientBillingContacts[${index}].billingContactPhone`, e.target.value)
          }}
          error={fieldErrors
            ?.find(f => f.fieldId === `clientBillingContacts[${index}].BillingContactPhone`)
            ?.error
          }
        />
        <InputActive
          id={`clientBillingContacts[${index}].billingContactEmail`}
          label="Billing Contact Email"
          value={form.getFieldProps(`clientBillingContacts[${index}].billingContactEmail`).value ?? ""}
          onChange={(e: any) => {
            form.setFieldValue(`clientBillingContacts[${index}].billingContactEmail`, e.target.value)
          }}
          error={fieldErrors
            ?.find(f => f.fieldId === `clientBillingContacts[${index}].BillingContactEmail`)
            ?.error
          }
        />
      </div>
      <Clear 
        className='x-icon'
        onClick={() => {
          form.setFieldValue("clientBillingContacts", form.values.clientBillingContacts?.filter((_, i) => i !== index))
        }}
      />
    </div>
  );
}

export const ContactInformationReadOnly: React.FC<ContactInformationProps> = ({
  form
}) => {
  return (
    <div>
      <TitleReadOnly>Primary Contacts</TitleReadOnly>
      {form.values.clientPrimaryContacts?.map((contact, index) => (
        <div 
          key={index}
          className='contact-row'
        >
          <ReadOnlyField
            value={form.getFieldProps(`clientPrimaryContacts[${index}].primaryContactName`).value} 
            field={ClientSchema.fields["clientPrimaryContacts"].innerType.fields["primaryContactName"]} />
          <ReadOnlyField
            value={form.getFieldProps(`clientPrimaryContacts[${index}].primaryContactPhone`).value} 
            field={ClientSchema.fields["clientPrimaryContacts"].innerType.fields["primaryContactPhone"]} />
          <ReadOnlyField
            value={form.getFieldProps(`clientPrimaryContacts[${index}].primaryContactEmail`).value} 
            field={ClientSchema.fields["clientPrimaryContacts"].innerType.fields["primaryContactEmail"]} />
        </div>
      ))}
      <TitleReadOnly>Billing Contacts</TitleReadOnly>
      {form.values.clientBillingContacts?.map((contact, index) => (
        <div key={index}>
          <ReadOnlyField
            value={form.getFieldProps(`clientBillingContacts[${index}].billingContactName`).value} 
            field={ClientSchema.fields["clientBillingContacts"].innerType.fields["billingContactName"]} />
          <ReadOnlyField
            value={form.getFieldProps(`clientBillingContacts[${index}].billingContactPhone`).value} 
            field={ClientSchema.fields["clientBillingContacts"].innerType.fields["billingContactPhone"]} />
          <ReadOnlyField
            value={form.getFieldProps(`clientBillingContacts[${index}].billingContactEmail`).value} 
            field={ClientSchema.fields["clientBillingContacts"].innerType.fields["billingContactEmail"]} />
        </div>
      ))}
    </div>
  )
}