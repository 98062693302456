import React, { HTMLProps, ReactNode } from "react";
import WarningOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import './FormErrorSection.scss';

type FormErrorSectionProps = HTMLProps<HTMLDivElement> & {
  sectionsWithErrors: string[];
  onSectionClick: (section: string) => void;
};

const FormErrorSection: React.FC<FormErrorSectionProps> = ({
  sectionsWithErrors,
  onSectionClick
}) => {

  const scrollToSection = (section: string) => {
    onSectionClick?.(section);

    const id = section.replace(/\s+/g, "").toLowerCase();
    const element = document.getElementById(id);
    element?.scrollIntoView({
      behavior: "smooth"
    })
  }

  return (
    <div className="form-error-section">
      <WarningOutlinedIcon className="warning-icon"/>
      <div>
        <p className="title">One or more required fields are not complete.</p>
        <p>Click on the links below to respond to the missing fields. Then, try submitting again.</p>
        <ul>
          {sectionsWithErrors.map(i => (
            <li key={i} onClick={() => scrollToSection(i)}>
              {i}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default FormErrorSection;