import React, { useState } from "react";
import AddEditClientHeader from "./AddEditClient.Header";
import FormSection, { FieldError } from "../../Form/FormSection";
import './AddEditClient.scss';
import { AsyncStatus } from "Models";
import { ENDPOINTS } from "Models/Templates/Routes/Routes";
import ContactOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import SuitcaseOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import FormErrorSection from "../../Form/FormErrorSection";
import { DEFAULT_CLIENT, useClientForm, ClientSchema } from "./useClientForm";
import { BillingContact, Client, PrimaryContact } from "../../../Models/Client";
import { ClientInformationActive, ClientInformationReadOnly } from "./AddEditClient.ClientInformation";
import { ContactInformationActive, ContactInformationReadOnly } from "./AddEditClient.ContactInformation";
import { ADD_EDIT } from "Navigation";
import { useDataFetcher, buildEndpoint, defaultDataTransform, useIdParam, useDataPoster } from "Utilities";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useHistory } from "react-router-dom";

const Sections = {
  ClientInformation: "Client Information",
  ContactInformation: "Contact Information",
  ClientSetUp: "Client Set-up",
}

const AddEditClient: React.FC = () => {
  const [id, isAdd] = useIdParam();
  const [client, loadingStatus] = useDataFetcher<Client>(
    buildEndpoint(ENDPOINTS.clients.base, id, ADD_EDIT),
    DEFAULT_CLIENT,
    defaultDataTransform,
    Client,
    true,
    "3.0"
  );  
  const [activeSection, setActiveSection] = useState(Sections.ClientInformation);
  const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);
  const [sectionsWithErrors, setSectionsWithErrors] = useState<string[]>([]);

  let history = useHistory();

  const handleResponse = (response: any) => {
    if (response.status >= 200 && response.status < 300) {
      toast.success("The Client has been updated");
    } else {
      toast.error("Some error ocurred");
      console.log(response);
    }
  };

  const handleError = (error: AxiosError) => {
    if (error.response?.status === 400) {
      toast.error("Please validate the highlighted fields");      

      const errors = Object.entries(error.response?.data.errors)
        .map(([key, value]) => ({
          fieldId: key,
          error: (value as string[])[0]
        }));

      setFieldErrors(errors);


      let sections = errors.map(e => {
        let id = e.fieldId;
        if (id.includes('[')) {
          id = id.split('[')[0];
        }
        
        const fieldDescription = ClientSchema.fields[id].describe();
        return fieldDescription.meta.section;
      }).reduce((errors, item) => {
        if (!errors.includes(item)) {
          errors.push(item);
        }
        return errors;
      }, []);

      setSectionsWithErrors(sections);
    }
  }

  const [postData] = useDataPoster(
    buildEndpoint(ENDPOINTS.clients.base),
    handleResponse,
    false,
    "3.0",
    handleError);

  const onSubmit = async (clientForm: any) => {
    if (!form.isValid) {

      let sections = Object.keys(form.errors).map(key => {        
        let id = key;
        if (id.includes('[')) {
          id = id.split('[')[0];
        }
        
        const fieldDescription = ClientSchema.fields[id].describe();
        return fieldDescription.meta.section;
      }).reduce((sections, item) => {
        if (!sections.includes(item)) {
          sections.push(item);
        }
        return sections;
      }, []);

      setSectionsWithErrors(sections);


      const errors = Object.keys(form.errors).map(key => ({
        fieldId: key,
        error: "Required field"
      }))

      setFieldErrors(errors);

      return;
    }

    let primaryContacts = clientForm.values.clientPrimaryContacts;
    if (primaryContacts && primaryContacts.length > 0) {
      primaryContacts = primaryContacts.filter((c: PrimaryContact) => {
        return !(!c.primaryContactName && !c.primaryContactPhone && !c.primaryContactEmail)
      });

      clientForm.values.clientPrimaryContacts = primaryContacts;
    }

    let billingContacts = clientForm.values.clientBillingContacts;
    if (billingContacts && billingContacts.length > 0) {
      billingContacts = billingContacts.filter((c: BillingContact) => {
        return !(!c.billingContactName && !c.billingContactPhone && !c.billingContactEmail)
      });

      clientForm.values.clientBillingContacts = billingContacts;
    }

    const response = await postData(clientForm.values);

    if (response.status === 200) {
      history.goBack();
    }
  }

  const form = useClientForm({
    onSubmit: onSubmit,
    client: client
  })

  return (
    <>
      {loadingStatus === AsyncStatus.Success && (
        <div>
          <AddEditClientHeader onSubmit={() => onSubmit(form)} />
          <div className="body">
            {sectionsWithErrors.length > 0 && (
              <FormErrorSection 
                sectionsWithErrors={sectionsWithErrors} 
                onSectionClick={(section) => setActiveSection(section)}
              />
            )}
            <FormSection
              title={Sections.ClientInformation}
              activeContent={<ClientInformationActive form={form} fieldErrors={fieldErrors} />}
              readOnlyContent={<ClientInformationReadOnly form={form} />}
              isActive={activeSection === Sections.ClientInformation}
              onActive={() => setActiveSection(Sections.ClientInformation)}
              initializedByDefault
              completed={!!form.values.name}
            />
            <FormSection
              title={Sections.ContactInformation}
              activeContent={<ContactInformationActive form={form} fieldErrors={fieldErrors} />}
              readOnlyContent={<ContactInformationReadOnly form={form} />}
              optional
              isActive={activeSection === Sections.ContactInformation}
              onActive={() => setActiveSection(Sections.ContactInformation)}
              initialIcon={<ContactOutlinedIcon />}
              initialText="Add contact details necessary to reach the client regarding billing inquiries or general matters"
              initializedByDefault={!isAdd}
              completed={true}
            />
            <FormSection
              title={Sections.ClientSetUp}
              readOnlyContent={(<p>view mode</p>)}
              activeContent={(<p>edit mode</p>)}
              isActive={activeSection === Sections.ClientSetUp}
              onActive={() => setActiveSection(Sections.ClientSetUp)}
              initialIcon={<SuitcaseOutlinedIcon />}
              initialText="Add a line of business, service, accompanying report questions, and pricing structures"
              initializedByDefault={!isAdd}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default AddEditClient;