import { DropdownValue } from "v3/Components/Form/Dropdown";

export type ReferralRequestDraftDocument = {
  fileId: string;
  fileName: string;
  size: string;
}


export class ReferralsRequest {
  id?: string;
  skipValidation?: boolean;
  referralSourceId?: string;
  referralDate?: Date;
  firstName?: string;
  lastName?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  phoneNumber?: string;
  email?: string;
  specialInstructions?: string;
  gender?: DropdownValue;
  referralRequestDraftDocuments?: ReferralRequestDraftDocument[];
  dateOfBirth?: Date;
}