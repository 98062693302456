import React, { useEffect, useMemo, useState } from "react";
import ReadOnlyField from "v3/Components/Form/ReadOnlyField";
import { FileUploader } from "v3/Components/Form/FileUploader";
import { CONTROLLERS } from "Models/Templates/Routes/Routes";
import { useIdParam } from "Utilities";
import { TitleActive } from "v3/Components/Form/TitleActive";
import { TitleReadOnly } from "v3/Components/Form/TitleReadOnly";
import { InputActive } from "v3/Components/Form/InputActive";
import { FilePondFile } from "filepond";
import './AddEditReferralsRequest.AdditionalInformation.scss';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { FieldError } from "v3/Components/Form/FormSection";
import { ReferralRequestSchema, useReferralRequestForm } from "./useReferralRequestForm";
import './AddEditReferralsRequest.ClaimantInformation.scss';
import Dropdown, { DropdownValue } from "v3/Components/Form/Dropdown";
import { useEnums } from "Context";
import { DatePickerInput } from "v3/Components/Form/DateTimePickers/DatePicker";
import { formatDate } from "@telerik/kendo-intl";

type ClaimantInformationProps = {
  form: ReturnType<typeof useReferralRequestForm>;
  fieldErrors?: FieldError[];
}

export const ClaimantInformationActive: React.FC<ClaimantInformationProps> = ({
  form,
  fieldErrors
}) => {
  const [id, isAdd, isEdit] = useIdParam();
  const { gender, state } = useEnums();

  return (
    <div className="">
      <TitleActive>Claimant Identity</TitleActive>
      <div className="active-row">
        <div className="active-fields">
          <InputActive
            id="firstName"
            name="firstName"
            label="First Name"
            value={form.getFieldProps("firstName").value ?? ""}
            onChange={(e: any) => {
              form.setFieldValue("firstName", e.target.value)
            }}
            required

            error={fieldErrors?.find(f => f.fieldId === "firstName")?.error}
          />
          <InputActive
            id="lastName"
            name="lastName"
            label="Last Name"
            value={form.getFieldProps("lastName").value ?? ""}
            onChange={(e: any) => {
              form.setFieldValue("lastName", e.target.value)
            }}
            required
            error={fieldErrors?.find(f => f.fieldId === "lastName")?.error}
          />
          <Dropdown
            label="Gender"
            hint="Select an option"
            required
            value={form.getFieldProps("gender").value}
            onChange={(value) => form.setFieldValue("gender", value)}
            options={[]}
            enums={gender}
          />
        </div>
      </div>
      <div className="active-row">
      <div className="active-fields">
        <DatePickerInput
          label={'Date of Birth'}
          value={form.getFieldProps("dateOfBirth").value}
          required
          onChange={(value) => form.setFieldValue("dateOfBirth", value.value)}
        />
      </div>
      </div>

      <TitleActive>Claimant Address</TitleActive>
      <div className="active-row">
        <InputActive
          id="streetAddress"
          name="streetAddress"
          label="Street Address"
          value={form.getFieldProps("streetAddress").value ?? ""}
          onChange={(e: any) => {
            form.setFieldValue("streetAddress", e.target.value)
          }}
          required
          error={fieldErrors?.find(f => f.fieldId === "streetAddress")?.error}
          className='input-container street-address'
        />
      </div>
      <div className="active-row">
        <div className="active-fields">
          <InputActive
            id="city"
            name="city"
            label="City"
            value={form.getFieldProps("city").value ?? ""}
            onChange={(e: any) => {
              form.setFieldValue("city", e.target.value)
            }}
            required
            error={fieldErrors?.find(f => f.fieldId === "city")?.error}
          />
          <Dropdown
            label="State"
            hint="Select an option"
            required
            value={form.getFieldProps("state").value}
            onChange={(value) => form.setFieldValue("state", value)}
            options={[]}
            enums={state}
            valueAsDisplayName
          />
          <InputActive
            id="zipCode"
            name="zipCode"
            label="Zip Code"
            value={form.getFieldProps("zipCode").value ?? ""}
            onChange={(e: any) => {
              form.setFieldValue("zipCode", e.target.value)
            }}
            required
            error={fieldErrors?.find(f => f.fieldId === "zipCode")?.error}
          />
        </div>
      </div>

      <TitleActive>Claimant Contact Details</TitleActive>
      <div className="active-row">
        <div className="active-fields">
          <InputActive
            id="phoneNumber"
            name="phoneNumber"
            label="Phone Number"
            value={form.getFieldProps("phoneNumber").value ?? ""}
            onChange={(e: any) => {
              form.setFieldValue("phoneNumber", e.target.value)
            }}
            required
            error={fieldErrors?.find(f => f.fieldId === "phoneNumber")?.error}
          />
          <InputActive
            id="email"
            name="email"
            label="Email"
            value={form.getFieldProps("email").value ?? ""}
            onChange={(e: any) => {
              form.setFieldValue("email", e.target.value)
            }}
            required
            error={fieldErrors?.find(f => f.fieldId === "email")?.error}
          />
        </div>
      </div>
    </div>
  );
}

export const ClaimantInformationReadOnly: React.FC<ClaimantInformationProps> = ({
  form
}) => {
  return (
    <div className="read-only-content">
      <TitleReadOnly>Claimant Identity</TitleReadOnly>
      <ReadOnlyField id="firstName" label="First Name" value={form.getFieldProps("firstName").value ?? ""} schema={ReferralRequestSchema} />
      <ReadOnlyField id="lastName" label="Last Name" value={form.getFieldProps("lastName").value ?? ""} schema={ReferralRequestSchema} />
      <ReadOnlyField id="gender" label="Gender" value={form.getFieldProps("gender.label").value ?? ""} schema={ReferralRequestSchema} />
      <ReadOnlyField id="dateOfBirth" label="Date of Birth" value={formatDate(form.getFieldProps("dateOfBirth").value, "d")} schema={ReferralRequestSchema} />
      <TitleReadOnly>Claimant Address</TitleReadOnly>
      <ReadOnlyField id="streetAddress" label="Street Address" value={form.getFieldProps("streetAddress").value ?? ""} schema={ReferralRequestSchema} />
      <ReadOnlyField id="city" label="City" value={form.getFieldProps("city").value ?? ""} schema={ReferralRequestSchema} />
      <ReadOnlyField id="state" label="state" value={form.getFieldProps("state.label").value ?? ""} schema={ReferralRequestSchema} />
      <ReadOnlyField id="zipCode" label="Zip Code" value={form.getFieldProps("zipCode").value ?? ""} schema={ReferralRequestSchema} />
      <TitleReadOnly>Claimant Contact Details</TitleReadOnly>
      <ReadOnlyField id="phoneNumber" label="Phone Number" value={form.getFieldProps("phoneNumber").value ?? ""} schema={ReferralRequestSchema} />
      <ReadOnlyField id="email" label="Email" value={form.getFieldProps("email").value ?? ""} schema={ReferralRequestSchema} />
    </div>
  )
}