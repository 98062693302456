import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import React, { HTMLProps } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { LightPageHeader } from '../../Components/Display';
import { AddButton } from '../../Components/Form';
import {
  DateCell,
  EditDeleteCell,
  EnumerationCell,
  GridButtonSection,
  LinkCell,
  MainGrid,
} from '../../Components/Grid';
import { SecureComponent } from '../../Components/Security';
import { FormProvider, GridProvider, useEnums, useGridState } from '../../Context';
import {
  defaultGridProps,
  FilterBarFilterType,
  FilterConfig,
  IFilterConfig,
} from '../../Models';
import { Permission } from '../../Models/Templates/Permission/Permission';
import { ENDPOINTS } from '../../Models/Templates/Routes/Routes';
import {
  addEditRoute,
  AddEditSubRoute,
  ClaimsSubRoute,
  ReferralSubRoute,
  ROUTES,
} from '../../Navigation';
import { ReferralsRequestSubRoute } from '../../Navigation/SubRoutes/ReferralsRequestSubRoutes';
import {
  buildEndpoint,
  buildRoute,
  DEFAULT_DATETIME24H_FORMAT,
  EMPTY_GUID,
  useGridSearch,
  useHasPermissions,
} from '../../Utilities';
import { AutoRefresh } from 'Components/Grid/AutoRefresh/AutoRefresh';
import NewReferralButton from 'v3/Components/Shared/NewReferralButton';

class ReferralsRequestSearch {
  searchKey?: string;
  id?: string;
}

export enum ReferralsRequestGridIds {
  DetailedSearch = 'detailedSearch',
}

export const ReferralsRequests: React.FC = () => {
  return (
    <GridProvider entityName="Referral Request">
      <Breadcrumb tag="nav" listTag="div">
        <BreadcrumbItem>
          <Link to={ROUTES.DASHBOARD}>Home</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>New Referrals</BreadcrumbItem>
      </Breadcrumb>
      <LightPageHeader tall noBorder className="page-header-shadow">
        <h1>New Referrals</h1>
      </LightPageHeader>
      <ReferralsRequestsFilteredGrid />
    </GridProvider>
  );
};

export type ReferralsRequestsFilteredGridProps =
  HTMLProps<ReferralsRequestsFilteredGridProps> & {
    id?: string;
    enableGridRefresh?: boolean;
  };

export const ReferralsRequestsFilteredGrid: React.FC<
  ReferralsRequestsFilteredGridProps
> = ({ id, ...props }) => {
  const referralSearch = new ReferralsRequestSearch();
  const filters: IFilterConfig[] = [
    new FilterConfig(
      'searchKey',
      FilterBarFilterType.SearchText,
      ' Name, SSN, or @'
    ),
    new FilterConfig(
      'dateOfBirth',
      FilterBarFilterType.DateRange,
      'Date Of Birth'
    ),
    new FilterConfig(
      'user',
      FilterBarFilterType.AsyncDropdown,
      'User',
      {
        readEndpoint: buildEndpoint(ENDPOINTS.users.base, 'getFiltered'),
      },
      undefined,
      true
    ),

    new FilterConfig(
      'id',
      FilterBarFilterType.SearchText,
      ' Name, ID, or #',
      undefined,
      (value) => (id ? id : '')
    ),
  ];
  referralSearch.id = id;

  return (
    <FormProvider formModel={referralSearch}>
      <ReferralsRequestsGrid id={id} enableGridRefresh={true} />
    </FormProvider>
  );
};

const ReferralsRequestsGrid: React.FC<ReferralsRequestsFilteredGridProps> = ({
  id,
  ...props
}) => {
  const [debounceSearchModel, gridToggler] = useGridSearch();
  const gridState = useGridState();

  let endpoint = ENDPOINTS.referralsRequests.read;
  return (
    <React.Fragment>
      <SecureComponent permissions={[Permission.AddReferralsRequest]}>
        <GridButtonSection>
        {props.enableGridRefresh &&
            <AutoRefresh onRefresh={() => {
              gridState.refreshGrid()
            }} />
          }
          <NewReferralButton />
        </GridButtonSection>
      </SecureComponent>

      <MainGrid
        refreshToggle={gridToggler}
        readEndpoint={endpoint}
        search={debounceSearchModel}
        enableGridRefresh={true}
        {...defaultGridProps}
      >
        <Column field='stradixNumber' cell={referralNameCell} title='Stradix Number' />
        <Column field="name" cell={referralNameCell} title="Claimant Name" />
        <Column field="clientName" title="Client" />
        <Column field="evalType" title="Type of Eval" />
        <Column field="claimNumber" title="Claim Number" cell={claimLinkCell} />
        <Column field="dueDate" title="Due Date" cell={dotNetDateColumn} />
        <Column
          field="referralDate"
          title="Referral Date"
          cell={dotNetDateColumn}
        />
        <Column field="statusName" title="Status" cell={ConvertCell} />
        <Column field="referralRequestType" title="Request Type" />
        <Column field="id" width="80" title="Actions" cell={ActionsCellEdit} />
      </MainGrid>
    </React.Fragment>
  );
};

const claimLinkCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  return props.dataItem.claimId ? (
    <LinkCell
      {...props}
      linkField={props.dataItem.claimantId}
      endpoint={buildRoute(
        ROUTES.CLAIM,
        props.dataItem.claimId,
        ClaimsSubRoute.View
      )}
    />
  ) : (
    <td>{props.dataItem.claimNumber}</td>
  );
};

const dotNetDateColumn: React.FC<GridCellProps> = (props: GridCellProps) => {
  return <DateCell {...props} dateFormat={DEFAULT_DATETIME24H_FORMAT} />;
};

const referralNameCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  if (props.dataItem.referralId) {
    return (
      <LinkCell
        endpoint={ROUTES.REFERRAL}
        linkField="referralId"
        subRoute={ReferralSubRoute.View}
        {...props}
      ></LinkCell>
    );
  }

  return (
    <LinkCell
      endpoint={ROUTES.REFERRALSREQUEST}
      linkField="id"
      subRoute={ReferralsRequestSubRoute.View}
      {...props}
    ></LinkCell>
  );
};

const convertText = (text: string) => 'Convert to Claimant';

const ConvertCell: React.FC<GridCellProps> = (props: GridCellProps) => {
  const hasPermission = useHasPermissions();
  const status = props.dataItem?.status;
  const convertText = (text: string) => 'Convert to Claimant';

  let content;

  if (
    status === 'Pending' &&
    hasPermission([Permission.ConvertReferralRequest])
  ) {
    content = props.dataItem?.referralRequestType?.displayName === 'Commercial' ? <td>{status}</td> : (
      <LinkCell
        {...props}
        endpoint={buildRoute(ROUTES.REFERRALREQUESTPROPOSALS)}
        linkField="id"
        field="status"
        convertFunction={convertText}
      >
        Convert to Claimant
      </LinkCell>
    );
  } else {
    content = <td>{status}</td>;
  }

  return content;
};

const ActionsCellEdit: React.FC<GridCellProps> = (props: GridCellProps) => {
  return (
      <EditDeleteCell
        editRoute={props.dataItem?.referralRequestType?.displayName === 'Commercial' ? '' : addEditRoute(AddEditSubRoute.ReferralsRequest)}
        editPermissions={[Permission.EditReferralsRequest]}
        deleteEndpoint={ENDPOINTS.referralsRequests.base}
        deletePermissions={[Permission.DeleteReferralsRequest]}
        {...props}
      ></EditDeleteCell>
  );
};

const ReferralRequestTypeCell: React.FC<GridCellProps> = (
  props: GridCellProps
) => {
  const { referralRequestType } = useEnums();

  return <EnumerationCell coreEnum={referralRequestType} {...props} />;
};