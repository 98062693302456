import React, { useEffect } from "react";
import { InputBlock } from "Components/Form";
import { ColSize, InputType } from "Models";
import { useClientForm, ClientSchema } from "./useClientForm";
import ReadOnlyField from "v3/Components/Form/ReadOnlyField";
import { FileUploader } from "v3/Components/Form/FileUploader";
import { CONTROLLERS } from "Models/Templates/Routes/Routes";
import { useIdParam } from "Utilities";
import { TitleActive } from "v3/Components/Form/TitleActive";
import { TitleReadOnly } from "v3/Components/Form/TitleReadOnly";
import { InputActive } from "v3/Components/Form/InputActive";
import { FilePondFile } from "filepond";
import './AddEditClient.ClientInformation.scss';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { FieldError } from "v3/Components/Form/FormSection";

type ClientInformationProps = {
  form: ReturnType<typeof useClientForm>;
  fieldErrors?: FieldError[];
}

export const ClientInformationActive: React.FC<ClientInformationProps> = ({
  form,
  fieldErrors
}) => {
  const [id, isAdd, isEdit] = useIdParam();

  return (
    <div className="">
      <TitleActive>Client Name</TitleActive>
      <InputActive 
        id="name"
        name="Client Name" 
        label="Client Name" 
        value={form.getFieldProps("name").value ?? ""}
        onChange={(e: any) => {
          form.setFieldValue("name", e.target.value)
        }}
        required
        style={{
          width: '33%'
        }}
        error={fieldErrors?.find(f => f.fieldId === "name")?.error}
      />
      <TitleActive>Settings</TitleActive>
      <div className="settings-container">
        <InputActive 
          id="contactEmail"
          name="CC Notification Email" 
          label="CC Notification Email" 
          description="This is the email that receives notifications alongside referral source"
          value={form.getFieldProps("contactEmail").value ?? ""}   
          onChange={(e: any) => {
            form.setFieldValue("contactEmail", e.target.value)
          }}
          error={fieldErrors?.find(f => f.fieldId === "contactEmail")?.error}
        />
        <div 
          className="input-container"
          style={{
            marginLeft: 38
          }}
        >
          <h6 className="label">Status <span style={{ color: "#BD0707" }}>*</span></h6>
          <InputBlock         
            name="isActive" 
            label="Active" 
            type={InputType.Checkbox} 
            colProps={{ xs: ColSize.OneThird }}
            value={form.getFieldProps("isActive").value ?? false}
            onChange={(e: any) => {
              form.setFieldValue("isActive", e.value)
            }}
            noPaddingMargin
          />
        </div>
      </div>
      <TitleActive>Contract(s)</TitleActive>
      <FileUploader
        label="Upload the contract(s) associated with this client" 
        fileEndpoint={`${CONTROLLERS.Clients}/ClientContract`} 
        name="clientContracts" 
        isEdit={isEdit}
        allowMultiple={true}
        formValues={form.values}
        onFilesChange={(files: any) => OnFilesChange(files, form)}
        >
      </FileUploader>
    </div>
  );
}

const OnFilesChange = (files: FilePondFile[], form: ReturnType<typeof useClientForm>) => {
  const newFiles = files.map((file) => {
    return {
      fileId: file.serverId,
      fileName: file.filename,
      size: file.fileSize,
      id: file.getMetadata('backendId')
    }
  })
  form.setFieldValue("clientContracts", newFiles)
}

export const ClientInformationReadOnly: React.FC<ClientInformationProps> = ({
  form
}) => {

  return (
    <div className="read-only-content">
      <TitleReadOnly>Client Name</TitleReadOnly>
      <ReadOnlyField
        id="name"
        schema={ClientSchema}
        value={form.getFieldProps("name").value} />
      <TitleReadOnly>Settings</TitleReadOnly>
      <ReadOnlyField
        id="contactEmail"
        schema={ClientSchema}
        value={form.getFieldProps("contactEmail").value} />
      <ReadOnlyField
        id="isActive"
        schema={ClientSchema}
        value={!!form.getFieldProps("isActive").value ? "Active" : "Inactive"} />
      <TitleReadOnly>Contract(s)</TitleReadOnly>
      {!form.values.clientContracts || form.values.clientContracts.length === 0 ? (
        <p>No contracts</p>
      ) : (
        form.values.clientContracts.map(c => (
          <div key={c.fileId} className="contract">
            <DescriptionOutlinedIcon />
            <span>{c.fileName}</span>
          </div>
        ))
      )}
    </div>
  )
}