import {
  AddEditAppointment,
  AddEditClaim,
  AddEditClaimant,
  AddEditClaimEscalation,  
  AddEditLineOfBusiness,
  AddEditGuideline,
  AddEditReferral,
  AddEditRole,
  AddEditUser,
  AddEditReviewer,
  AddEditInternalNote,
  AddEditManagedReviewer,
} from 'Features/AddEdit';
import { AddEditPhysician } from 'Features/AddEdit/AddEditPhysician';
import { AddEditNote } from 'Features/AddEdit/AddEditNote';
import { Permission } from 'Models/Templates/Permission/Permission';
import { withIdParam } from 'Navigation/NavigationHelpers';
import { ActionsSubRouteConfig, SubRoutes } from 'Navigation/NavigationTypes';
import { AddEditReferralsRequestCommercial } from 'Features/AddEdit/AddEditReferralsRequestCommercial';
import AddEditClient from 'v3/Components/Pages/AddEditClient/AddEditClient';
import AddEditReferralsRequest from 'v3/Components/Pages/AddEditReferralsRequest/AddEditReferralsRequest';

export enum AddEditSubRoute {
  Role = '/Role',
  SettingType = '/SettingType',
  User = '/User',
  Report = '/Report',
  Referral = '/Referral',
  Claimant = '/Claimant',
  Appointment = '/Appointment',
  Claims = '/Claims',
  Guideline = '/Guideline',
  ReferralsRequest = '/ReferralsRequest',
  Physician = '/Physician',
  Client = '/Client',
  Reviewer = '/Reviewer',
  ClaimEscalation = '/ClaimEscalation',
  Notes = '/Notes',
  InternalNotes = '/InternalNotes',
  ManagedReviewer = '/ManagedReviewer',
  ReferralsRequestCommercial = '/ReferralsRequestCommercial',
  LineOfBusiness = '/LineOfBusiness'
}

export const AddEditSubRoutes: SubRoutes = {
  [AddEditSubRoute.Role]: new ActionsSubRouteConfig(
    withIdParam(AddEditSubRoute.Role),
    AddEditRole,
    [Permission.AddRole, Permission.EditRole]
  ),
  [AddEditSubRoute.User]: new ActionsSubRouteConfig(
    withIdParam(AddEditSubRoute.User),
    AddEditUser,
    [Permission.AddUser, Permission.EditUser]
  ),
  [AddEditSubRoute.ManagedReviewer]: new ActionsSubRouteConfig(
    withIdParam(AddEditSubRoute.ManagedReviewer),
    AddEditManagedReviewer,
    [Permission.AssignManagedReviewer, Permission.ViewManagedReviewer]
  ),
  [AddEditSubRoute.Claimant]: new ActionsSubRouteConfig(
    withIdParam(AddEditSubRoute.Claimant),
    AddEditClaimant,
    [Permission.AddClaimant, Permission.EditClaimant]
  ),
  [AddEditSubRoute.Appointment]: new ActionsSubRouteConfig(
    withIdParam(AddEditSubRoute.Appointment) + '/:referralid?',
    AddEditAppointment,
    [Permission.AddAppointment, Permission.EditAppointment]
  ),
  [AddEditSubRoute.Referral]: new ActionsSubRouteConfig(
    withIdParam(AddEditSubRoute.Referral) + '/:claimid?',
    AddEditReferral,
    [Permission.AddReferral, Permission.EditReferral]
  ),
  [AddEditSubRoute.Claims]: new ActionsSubRouteConfig(
    withIdParam(AddEditSubRoute.Claims) + '/:claimantid?',
    AddEditClaim,
    [Permission.AddClaim, Permission.EditClaim]
  ),
  [AddEditSubRoute.Guideline]: new ActionsSubRouteConfig(
    withIdParam(AddEditSubRoute.Guideline) + '/:guidelineid?',
    AddEditGuideline,
    [Permission.AddGuideline, Permission.EditGuideline]
  ),
  [AddEditSubRoute.ReferralsRequest]: new ActionsSubRouteConfig(
    withIdParam(AddEditSubRoute.ReferralsRequest) + '/:referralsrequestid?',
    AddEditReferralsRequest,
    [Permission.AddReferralsRequest, Permission.EditReferralsRequest]
  ),
  [AddEditSubRoute.Physician]: new ActionsSubRouteConfig(
    withIdParam(AddEditSubRoute.Physician),
    AddEditPhysician,
    [Permission.AddPhysician, Permission.EditPhysician]
  ),
  [AddEditSubRoute.Client]: new ActionsSubRouteConfig(
    withIdParam(AddEditSubRoute.Client),
    AddEditClient,
    [Permission.AddClient, Permission.EditClient]
  ),
  [AddEditSubRoute.ClaimEscalation]: new ActionsSubRouteConfig(
    withIdParam(AddEditSubRoute.ClaimEscalation) + '/:claimid?',
    AddEditClaimEscalation,
    [Permission.AddEscalation, Permission.EditEscalation]
  ),
  [AddEditSubRoute.Reviewer]: new ActionsSubRouteConfig(
    withIdParam(AddEditSubRoute.Reviewer),
    AddEditReviewer,
    [Permission.AddReviewer, Permission.EditReviewer]
  ),
  [AddEditSubRoute.Notes]: new ActionsSubRouteConfig(
    withIdParam(AddEditSubRoute.Notes) + '/:referralid?',
    AddEditNote,
    [Permission.AddNote, Permission.EditNote]
  ),
  [AddEditSubRoute.InternalNotes]: new ActionsSubRouteConfig(
    withIdParam(AddEditSubRoute.InternalNotes) + '/:referralid?',
    AddEditInternalNote,
    [Permission.AddInternalNote, Permission.EditInternalNote]
  ),
  [AddEditSubRoute.ReferralsRequestCommercial]: new ActionsSubRouteConfig(
    withIdParam(AddEditSubRoute.ReferralsRequestCommercial) + '/:referralid?',
    AddEditReferralsRequestCommercial,
    [Permission.AddReferralsRequestCommercial]
  ),
  [AddEditSubRoute.LineOfBusiness]: new ActionsSubRouteConfig(
    withIdParam(AddEditSubRoute.LineOfBusiness),
    AddEditLineOfBusiness,
    [Permission.AddLineOfBusiness, Permission.EditLineOfBusiness]
  ),
};
