import React, { useEffect, useState } from "react";
import AddEditReferralsRequestHeader from "./AddEditReferralsRequest.Header";
import FormErrorSection from "v3/Components/Form/FormErrorSection";
import FormSection, { FieldError } from "v3/Components/Form/FormSection";
import { useHistory } from "react-router-dom";
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';

import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import { buildEndpoint, defaultDataTransform, useDataFetcher, useDataPoster, useIdParam } from "Utilities";
import { ENDPOINTS } from "Models/Templates/Routes/Routes";
import { ADD_EDIT } from "Navigation";
import { ReferralsRequest } from "v3/Models/ReferralRequest";
import { DEFAULT_REFERRAL_REQUEST, useReferralRequestForm } from "./useReferralRequestForm";
import { AsyncStatus } from "Models";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { Button } from "@progress/kendo-react-buttons";
import { AdditionalInformationActive, AdditionalInformationReadOnly } from "./AddEditReferralsRequest.AdditionalInformation";
import { ClaimantInformationActive, ClaimantInformationReadOnly } from "./AddEditReferralsRequest.ClaimantInformation";

const Sections = {
  ClaimantInformation: "Claimant Information",
  ClaimDetails: "Claim Details",
  AttorneyDetails: "Attorney Details",
  ReportDetails: "Report Details",
  ReportQuestions: "Report Questions",
  AdditionalInformation: "Additional Information"
}

const AddEditReferralsRequest: React.FC = () => {
  const [activeSection, setActiveSection] = useState(Sections.ClaimantInformation);
  const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);
  const [sectionsWithErrors, setSectionsWithErrors] = useState<string[]>([]);
  const onSubmit = async (referralForm: any) => {
  }
  const form = useReferralRequestForm({
    onSubmit: onSubmit,
    referralRequest: DEFAULT_REFERRAL_REQUEST
  })

  let history = useHistory();

  const [id, isAdd] = useIdParam();
  const [referralRequest, loadingStatus] = useDataFetcher<ReferralsRequest>(
    buildEndpoint(ENDPOINTS.referralsRequestsDraft.base, id, ADD_EDIT),
    DEFAULT_REFERRAL_REQUEST,
    defaultDataTransform,
    ReferralsRequest,
    true,
    "3.0"
  );

  const handleResponse = (response: any) => {
    if (response.status >= 200 && response.status < 300) {
      toast.success("The Referral Request has been updated");
    } else {
      toast.error("Something went wrong. Please try again");
      console.log(response);
    }
  };

  const handleError = (error: AxiosError) => {
    if (error.response?.status === 400) {
      toast.error("Please validate the highlighted fields");
      console.log(error.response)
    }
  }

  const [postData] = useDataPoster(
    buildEndpoint(ENDPOINTS.referralsRequestsDraft.base),
    handleResponse,
    false,
    "3.0",
    handleError);

  const saveDraft = async () => {
    referralRequest.skipValidation = true;
    const response = await postData(referralRequest);
    console.log("response")
    console.log(response)
    history.goBack();
  }

  return loadingStatus === AsyncStatus.Success ? (
    <div>
      <AddEditReferralsRequestHeader
        onDiscard={() => {}}
        onSave={saveDraft}
      />
      <div className="body">
        {sectionsWithErrors.length > 0 && (
          <FormErrorSection
            sectionsWithErrors={sectionsWithErrors}
            onSectionClick={(section) => setActiveSection(section)}
          />
        )}
        <FormSection
          title={Sections.ClaimantInformation}
          activeContent={<ClaimantInformationActive form={form} fieldErrors={fieldErrors} />}
          readOnlyContent={<ClaimantInformationReadOnly form={form} />}
          isActive={activeSection === Sections.ClaimantInformation}
          onActive={() => setActiveSection(Sections.ClaimantInformation)}
          initializedByDefault
          completed={false}
        />
        <FormSection
          title={Sections.ClaimDetails}
          activeContent={<p>Active state content</p>}
          readOnlyContent={<p>Read-only state content</p>}
          isActive={activeSection === Sections.ClaimDetails}
          onActive={() => setActiveSection(Sections.ClaimDetails)}
          initialIcon={<StickyNote2OutlinedIcon />}
          initialText="Add information about the claim details such as claim number and date of injury"
          initializedByDefault={false}
          completed={false}
        />
        <FormSection
          title={Sections.AttorneyDetails}
          activeContent={<p>Active state content</p>}
          readOnlyContent={<p>Read-only state content</p>}
          isActive={activeSection === Sections.AttorneyDetails}
          onActive={() => setActiveSection(Sections.AttorneyDetails)}
          initialIcon={<GavelOutlinedIcon />}
          initialText="Add information about the attorneys on the claim"
          initializedByDefault={false}
          completed={false}
          optional
        />
        <FormSection
          title={Sections.ReportDetails}
          activeContent={<p>Active state content</p>}
          readOnlyContent={<p>Read-only state content</p>}
          isActive={activeSection === Sections.AttorneyDetails}
          onActive={() => setActiveSection(Sections.AttorneyDetails)}
          initialIcon={<GavelOutlinedIcon />}
          initialText="Add details about the report and such as the due date of the report and what requirements it has"
          initializedByDefault={false}
          completed={false}
        />
        <FormSection
          title={Sections.ReportQuestions}
          activeContent={<p>Active state content</p>}
          readOnlyContent={<p>Read-only state content</p>}
          isActive={activeSection === Sections.AttorneyDetails}
          onActive={() => setActiveSection(Sections.AttorneyDetails)}
          initialIcon={<ContactSupportOutlinedIcon />}
          initialText="Select a service type in “Report Details” above in order to view the corresponding questions for this referral"
          initializedByDefault={false}
          completed={false}
        />
        <FormSection
          title={Sections.AdditionalInformation}
          activeContent={<AdditionalInformationActive form={form} fieldErrors={[]} />}
          readOnlyContent={<AdditionalInformationReadOnly form={form} />}
          isActive={activeSection === Sections.AdditionalInformation}
          onActive={() => setActiveSection(Sections.AdditionalInformation)}
          initialIcon={<GavelOutlinedIcon />}
          initialText="Add special instructions and the medical records tied to this claim"
          initializedByDefault={false}
          completed={false}
          optional
        />
        <div className="referral-footer">
          <Button
            primary
            look="outline"
            onClick={saveDraft}
          >
            Save Draft & Finish Later
          </Button>
          <Button
            primary
            style={{ marginLeft: 24 }}
            onClick={history.goBack}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  ) : (<></>);
}

export default AddEditReferralsRequest;