import React, { HTMLProps } from "react";
import './ReadOnlyField.scss';

type ReadOnlyFieldProps = HTMLProps<HTMLDivElement> & {
  id?: string;
  schema?: any;
  value: any;
  field?: any;
};

const ReadOnlyField: React.FC<ReadOnlyFieldProps> = ({
  id,
  schema,
  value,
  field
}) => {
  let field2 = field ?? schema.fields[id ?? ""];

  const label = field2.describe().meta.label;
  const isRequired = field2.describe().tests.some((t: any) => t.name === 'required');

  let valueLabel;

  if (!!value) {
    valueLabel = value;
  } else if (isRequired) {
    valueLabel = "Incomplete";
  } else {
    valueLabel = "Empty";
  }

  return (
    <div className="read-only-field">
      <span>{label}</span>
      <div className="dashed-line"></div>
      <span className={`value ${isRequired && !value && "incomplete"}`}>
        {valueLabel}
      </span>
    </div>
  )
}

export default ReadOnlyField;