import React from 'react';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import './DatePicker.scss';

interface DatePickerInputProps {
  label: string;
  value: Date;
  onChange: (e: any) => void;
  required?: boolean;
}

export const DatePickerInput = (props: DatePickerInputProps) => {
  const { label, required, value, onChange } = props;
  return (
    <div className="date-picker-wrapper">
      <span className="date-picker-label">
        {label} {required ? <span className="required">*</span> : '(Optional)'}
      </span>
      <DatePicker
        value={new Date(value)}
        onChange={onChange}
        format="MM/dd/yyyy"
      />
    </div>
  );
};
