import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import 'App.scss';
import { SidebarLayoutRoute } from 'Components/Navigation/SidebarLayoutRoute';
import { EntityProvider } from 'Context';
import { AddEditMain } from 'Features/AddEdit/AddEditMain';
import { AppointmentMain } from 'Features/Appointments';
import { CaseManagementMain } from 'Features/CaseManagement';
import { OpenReferralsCommercialMain } from 'Features/CaseManagement/OpenReferralsCommercialMain';
import { ViewClaimantMain } from 'Features/Claimants';
import { ClaimMain } from 'Features/Claims';
import { ConfigurationHeader, ConfigurationMain } from 'Features/Configuration';
import { DashboardMain } from 'Features/Dashboard';
import { DocumentsMain } from 'Features/Documents/DocumentsMain';
import { ElmahError, ElmahErrors } from 'Features/Elmah/ElmahErrors';
import { EntitiesMain } from 'Features/Entities/EntitiesMain';
import { Unauthorized } from 'Features/Error/Unauthorized';
import { EscalationsExport } from 'Features/EscalationsExport';
import { ExamplesMain } from 'Features/Examples/ExamplesMain';
import { ViewGuidelineMain } from 'Features/Guidelines/ViewGuidelineMain';
import { Invoices } from 'Features/Invoices';
import { MyInvoices } from 'Features/MyInvoices';
import { NetworkDevelopmentMain } from 'Features/NetworkDevelopment';
import {
  AddAuditReport,
  QualityAssuranceMain,
} from 'Features/QualityAssurance';
import { ReferralCommercialMain, ReferralMain } from 'Features/Referrals';
import {
  ReferralsRequestProposalsMain,
  ReferralsRequestProposaslCommercial,
} from 'Features/ReferralsRequestProposals';
import { ReferralsRequestMain } from 'Features/ReferralsRequests';
import { ReviewerConvertMain } from 'Features/ReviewerConvert';
import { ExternalApiSwagger } from 'Features/Swagger/ExternalApiSwagger';
import { Swagger } from 'Features/Swagger/Swagger';
import {
  UserManagementHeader,
  UserManagementMain,
} from 'Features/UserManagement';
import { ViewMain } from 'Features/View';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import { fullRoute, ROUTES } from 'Navigation';
import React from 'react';
import { Switch } from 'react-router-dom';
import { ComponentLibrary } from 'v3/Components/ComponentLibrary';
import { LayoutRoute } from './Components/Navigation';
import { Layout } from './Features/Shared';

library.add(fab);

export const App: React.FC = () => {
  return (
    <Layout>
      <Switch>
        <LayoutRoute
          path={ROUTES.COMPONENT_EXAMPLES}
          component={ComponentLibrary}
        />
        <LayoutRoute path={ROUTES.SWAGGER} component={Swagger} />
        <LayoutRoute path={ROUTES.API_SWAGGER} component={ExternalApiSwagger} />
        <LayoutRoute
          path={ROUTES.DASHBOARD}
          component={DashboardMain}
          layoutProps={{ noPadding: true }}
        />
        <LayoutRoute path={ROUTES.ENTITIES} component={EntitiesMain} />
        <LayoutRoute path={ROUTES.DOCUMENTS} component={DocumentsMain} />
        <LayoutRoute
          path={ROUTES.NETWORK_DEVELOPMENT}
          component={NetworkDevelopmentMain}
        />
        <LayoutRoute path={ROUTES.QA} component={QualityAssuranceMain} />
        <LayoutRoute
          path={ROUTES.CASEMANAGEMENT}
          component={CaseManagementMain}
        />
        <LayoutRoute
          path={ROUTES.COMMERCIAL}
          component={OpenReferralsCommercialMain}
        />
        <LayoutRoute path={ROUTES.INVOICES} component={Invoices} />
        <LayoutRoute path={ROUTES.MY_INVOICES} component={MyInvoices} />
        <LayoutRoute path={ROUTES.UNAUTHORIZED} component={Unauthorized} />

        <LayoutRoute
          route={ROUTES.ADD_EDIT}
          path={ROUTES.ADD_EDIT}
          component={AddEditMain}
          layoutProps={{ noPadding: true }}
        />
        <SidebarLayoutRoute
          route={ROUTES.USER_MANAGEMENT}
          path={ROUTES.USER_MANAGEMENT}
          component={UserManagementMain}
          headerComponent={UserManagementHeader}
          layoutProps={{ hasSubHeader: true }}
        />

        <SidebarLayoutRoute
          route={ROUTES.CONFIGURATION}
          path={fullRoute(ROUTES.CONFIGURATION)}
          component={ConfigurationMain}
          headerComponent={ConfigurationHeader}
          layoutProps={{ hasSubHeader: true }}
        />

        <LayoutRoute
          route={ROUTES.ELMAH}
          path={ROUTES.ELMAH}
          component={ElmahErrors}
          exact
        />
        <LayoutRoute
          route={ROUTES.ELMAH}
          path={ROUTES.ELMAH + '/:id'}
          component={ElmahError}
          exact
        />
        <SidebarLayoutRoute
          route={ROUTES.EXAMPLES}
          path={ROUTES.EXAMPLES}
          component={ExamplesMain}
        />
        <LayoutRoute
          route={ROUTES.VIEW}
          path={ROUTES.VIEW}
          component={ViewMain}
          layoutProps={{ noPadding: true }}
        />
        {/*You would add your error route here because /* is a catch all route */}
        {/* <LayoutRoute path="/*" component={DashboardMain} /> */}
        <LayoutRoute
          route={ROUTES.REFERRAL}
          path={fullRoute(ROUTES.REFERRAL)}
          component={ReferralMain}
          providerComponent={EntityProvider}
          providerProps={{ getEntityUrl: ENDPOINTS.referrals.getInfoModel }}
          headerProps={{ hasTallHeader: false }}
        />
        <LayoutRoute
          route={ROUTES.REFERRALCOMMERCIAL}
          path={fullRoute(ROUTES.REFERRALCOMMERCIAL)}
          component={ReferralCommercialMain}
          providerComponent={EntityProvider}
          providerProps={{
            getEntityUrl: ENDPOINTS.referralsCommercial.getInfoModel,
          }}
          headerProps={{ hasTallHeader: false }}
        />
        <LayoutRoute
          route={ROUTES.REFERRALREQUESTPROPOSALS}
          path={ROUTES.REFERRALREQUESTPROPOSALS + '/:id'}
          component={ReferralsRequestProposalsMain}
          exact
        />
        <LayoutRoute
          route={ROUTES.REFERRALREQUESTPROPOSALSCOMMERCIAL}
          path={ROUTES.REFERRALREQUESTPROPOSALSCOMMERCIAL + '/:id'}
          component={ReferralsRequestProposaslCommercial}
          exact
        />

        <LayoutRoute
          route={ROUTES.CLAIMANT}
          path={fullRoute(ROUTES.CLAIMANT)}
          providerComponent={EntityProvider}
          providerProps={{ getEntityUrl: ENDPOINTS.claimants.getInfoModel }}
          component={ViewClaimantMain}
        />
        <LayoutRoute
          route={ROUTES.CLAIM}
          path={fullRoute(ROUTES.CLAIM)}
          component={ClaimMain}
          providerComponent={EntityProvider}
          providerProps={{ getEntityUrl: ENDPOINTS.claims.getInfoModel }}
          headerProps={{ hasTallHeader: false }}
        />
        <LayoutRoute
          route={ROUTES.REFERRALSREQUEST}
          path={fullRoute(ROUTES.REFERRALSREQUEST)}
          component={ReferralsRequestMain}
          providerComponent={EntityProvider}
          providerProps={{
            getEntityUrl: ENDPOINTS.referralsRequests.getInfoModel,
          }}
          headerProps={{ hasTallHeader: false }}
        />
        <LayoutRoute
          route={ROUTES.APPOINTMENT}
          path={fullRoute(ROUTES.APPOINTMENT)}
          component={AppointmentMain}
          providerComponent={EntityProvider}
          providerProps={{ getEntityUrl: ENDPOINTS.appointments.getInfoModel }}
          headerProps={{ hasTallHeader: false }}
        />
        <LayoutRoute
          route={ROUTES.REVIEWER_CONVERT}
          path={ROUTES.REVIEWER_CONVERT + '/:id'}
          component={ReviewerConvertMain}
          exact
        />
        <LayoutRoute
          route={ROUTES.ADD_AUDIT_REPORT}
          path={ROUTES.ADD_AUDIT_REPORT + '/:id'}
          component={AddAuditReport}
          exact
        />
        <LayoutRoute
          route={ROUTES.GUIDELINE}
          path={fullRoute(ROUTES.GUIDELINE)}
          providerComponent={EntityProvider}
          providerProps={{ getEntityUrl: ENDPOINTS.guidelines.getInfoModel }}
          component={ViewGuidelineMain}
        />
        <LayoutRoute
          path={ROUTES.ESCALATIONS_EXPORT}
          component={EscalationsExport}
        />
      </Switch>
    </Layout>
  );
};
