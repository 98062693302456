import { ReferralsRequest } from "v3/Models/ReferralRequest";
import { useFormik } from "formik";
import * as Yup from "yup";
import { first } from "lodash";

export const DEFAULT_REFERRAL_REQUEST: ReferralsRequest = {
  id: "",
  skipValidation: true,
  referralSourceId: "",
  referralDate: new Date(),
  firstName: "",
  lastName: "",
  streetAddress: "",
  city: "",
  state: "",
  zipCode: "",
  phoneNumber: "",
  email: "",
  specialInstructions: "",
  referralRequestDraftDocuments: [],
  dateOfBirth: new Date(),
};

type UseReferralRequestFormProps = {
  onSubmit: (values: ReferralsRequest) => void;
  referralRequest?: ReferralsRequest | null;
};

export const useReferralRequestForm = ({
  onSubmit,
  referralRequest,
}: UseReferralRequestFormProps) => {
  const formik = useFormik<ReferralsRequest>({
    enableReinitialize: true,
    initialValues: referralRequest ?? DEFAULT_REFERRAL_REQUEST,
    onSubmit: onSubmit,
    validateOnMount: true
  });
  
  return formik;
};

const AddEditReferralRequestSections = {
  AdditionalInformation: "Additional Information",
  ClaimantInformation: "Claimant Information"
}

export const ReferralRequestSchema = Yup.object().shape({
  specialInstructions: Yup.bool().meta({ 
    label: "Comments",
    section: AddEditReferralRequestSections.AdditionalInformation
  }),
  firstName: Yup.string().nullable().meta({
    label: "First Name",
    section: AddEditReferralRequestSections.ClaimantInformation
  }),
  lastName: Yup.string().nullable().meta({
    label: "Last Name",
    section: AddEditReferralRequestSections.ClaimantInformation
  }),
  streetAddress: Yup.string().nullable().meta({
    label: "Street Address",
    section: AddEditReferralRequestSections.ClaimantInformation
  }),
  city: Yup.string().nullable().meta({
    label: "City",
    section: AddEditReferralRequestSections.ClaimantInformation
  }),
  zipCode: Yup.string().nullable().meta({
    label: "Zip Code",
    section: AddEditReferralRequestSections.ClaimantInformation
  }),
  phoneNumber: Yup.string().nullable().meta({
    label: "Phone Number",
    section: AddEditReferralRequestSections.ClaimantInformation
  }),
  email: Yup.string().nullable().meta({
    label: "Email",
    section: AddEditReferralRequestSections.ClaimantInformation
  }),
  state: Yup.object().nullable().meta({
    label: "State",
    section: AddEditReferralRequestSections.ClaimantInformation
  }),
  gender: Yup.object().nullable().meta({
    label: "Gender",
    section: AddEditReferralRequestSections.ClaimantInformation
  }),
  dateOfBirth: Yup.date().nullable().meta({
    label: "Date of Birth",
    section: AddEditReferralRequestSections.ClaimantInformation
  })
});